
      import API from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../node_modules/css-loader/dist/cjs.js!../../node_modules/source-map-loader/dist/cjs.js!./CategoryCheckbox.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function insertIntoScriptTag(css) {
            // Ensure css is a style element before proceeding
            if (css instanceof HTMLStyleElement) {
              setTimeout(() => {
                // console.log("✅ Extracting CSS from style tag"); // Debugging
                // Extract raw CSS from the style element
                const cssText = css.innerHTML || Array.from(css.sheet?.cssRules || [])
                  .map(rule => rule.cssText)
                  .join("\n");
                if (cssText.trim()) {
                  const script = document.createElement("script");
                  script.type = "text/plain";
                  script.dataset.e3dcMapShadowStyle = "true";
                  script.textContent = cssText; // Store the raw CSS
                  document.head.appendChild(script);
                }
                css.remove(); // Remove the original <style> tag
              }, 0);
            }
          };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../node_modules/css-loader/dist/cjs.js!../../node_modules/source-map-loader/dist/cjs.js!./CategoryCheckbox.css";
       export default content && content.locals ? content.locals : undefined;
