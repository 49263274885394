import SearchResultItem from './SearchResultItem';
import './SearchResult.css';

const categories = {
    hasPvSpeicher: "PV & Speicher",
    hasEMobility: "E-Mobilität",
    hasSmartMeter: "Smart-Meter",
    hasWaermepumpeInstall: "Wärmepumpe Installation",
    hasWaermepumpeIntegration: "Wärmepumpe Integration"
};

const SearchResult = ({ showResult, locations, onSearchResultItemEnter, onSearchResultItemLeave, onSearchResultItemClicked, searchString, selectedCategories, selectedLocation }) => {

    const handleMouseEnter = (location, index) => {
        if (onSearchResultItemEnter) {
            onSearchResultItemEnter(location, index);
        }
    };

    const handleMouseLeave = (location, index) => {
        if (onSearchResultItemLeave) {
            onSearchResultItemLeave(location, index);
        }
    };

    const handleClick = (location, index) => {
        if (onSearchResultItemClicked) {
            onSearchResultItemClicked(location, index);
        }
    };

    const filteredBy = () => {
        const selected = Object.keys(selectedCategories)
            .filter((key) => selectedCategories[key]);
        return selected.length > 0
            ? '(gefiltert nach ' + selected.map((key) => {
                return categories[key];
            })
            .join(', ') + ')'
            : '';
    }

    return (
        <div className='search-result-container'>
        {showResult ? (
            <>
            <div className='search-result-notice px-4 py-3'>Hier sind die <b>{locations.length}</b> nächsten E3/DC-Fachpartner in der Nähe von {searchString} {filteredBy()}</div>
            <div className='search-result-items-container'>
                {locations.map((location, index) => (
                <SearchResultItem
                    key={index}
                    location={location}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                    index={index}
                    isSelected={selectedLocation && selectedLocation.id == location.id}
                />
                ))}
            </div>
            </>
        ) : (
            <div className='search-result-notice px-4 py-3'>Bitte geben Sie einen Ort ein, um E3/DC-Fachpartner in Ihrer Nähe zu finden.</div>
        )}
        </div>
    );
};

export default SearchResult;
