// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {

.search-result-item-container {
    padding: 5px 20px;
    height: 100%;
    border-top: solid 1px #eee;
    cursor: pointer;
}

.search-result-item-container.active {
    background-color: #f8f8f8;
}

.search-result-item-container:hover {
    background-color: #f8f8f8;
}

.search-result-item-icon {
    color: transparent;
    width: 64px;
    height: 64px;
}

.search-result-item-name {
    letter-spacing: 0;
    text-transform: none;
    font-weight: 700;
    font-size: .875rem;
    line-height: 1.25rem;
}

.search-result-item-name svg {
    height: 1.25rem;
    color: rgb(117, 117, 117);
}

.search-result-item-name span {
    color: rgb(117, 117, 117);
    font-size: 12px;
    font-weight: 400;
}

.search-result-item-address {
    font-size: .875rem;
    line-height: 1.25rem;
    color: gray;
}

.search-result-item-services {
    gap: 8px;
}

.search-result-item-service {
    width: 20px;
    width: 20px;
    color: #ddd;
    /* margin-right: 8px; */
}

.search-result-item-service.active {
    color: #66c530;
}

}`, "",{"version":3,"sources":["webpack://./src/components/SearchResultItem.css"],"names":[],"mappings":"AAAA;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,WAAW;IACX,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA","sourcesContent":[".map-container {\n\n.search-result-item-container {\n    padding: 5px 20px;\n    height: 100%;\n    border-top: solid 1px #eee;\n    cursor: pointer;\n}\n\n.search-result-item-container.active {\n    background-color: #f8f8f8;\n}\n\n.search-result-item-container:hover {\n    background-color: #f8f8f8;\n}\n\n.search-result-item-icon {\n    color: transparent;\n    width: 64px;\n    height: 64px;\n}\n\n.search-result-item-name {\n    letter-spacing: 0;\n    text-transform: none;\n    font-weight: 700;\n    font-size: .875rem;\n    line-height: 1.25rem;\n}\n\n.search-result-item-name svg {\n    height: 1.25rem;\n    color: rgb(117, 117, 117);\n}\n\n.search-result-item-name span {\n    color: rgb(117, 117, 117);\n    font-size: 12px;\n    font-weight: 400;\n}\n\n.search-result-item-address {\n    font-size: .875rem;\n    line-height: 1.25rem;\n    color: gray;\n}\n\n.search-result-item-services {\n    gap: 8px;\n}\n\n.search-result-item-service {\n    width: 20px;\n    width: 20px;\n    color: #ddd;\n    /* margin-right: 8px; */\n}\n\n.search-result-item-service.active {\n    color: #66c530;\n}\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
