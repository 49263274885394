// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {

    .category-checkbox-container {
        padding: 8px 8px;
        color: grey;
        max-width: 80px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .category-checkbox-container > label {
        cursor: inherit;
    }

    .category-checkbox-container:hover {
        color: white;
        cursor: pointer;
    }

    .category-checkbox-icon {
        height: 30px;
        width: 30px;
        margin-bottom: 4px;
    }

    .category-checkbox-label {
        text-align: center;
        font-size: .75rem;
        line-height: 1rem;
    }

    .category-checkbox-icon.active {
        color: #66c530;
    }

    .category-checkbox-label.active {
        color: white;
    }

    }
`, "",{"version":3,"sources":["webpack://./src/components/CategoryCheckbox.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,gBAAgB;QAChB,WAAW;QACX,eAAe;QACf,YAAY;QACZ,cAAc;IAClB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,WAAW;QACX,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;QACjB,iBAAiB;IACrB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,YAAY;IAChB;;IAEA","sourcesContent":[".map-container {\n\n    .category-checkbox-container {\n        padding: 8px 8px;\n        color: grey;\n        max-width: 80px;\n        flex-grow: 0;\n        flex-shrink: 0;\n    }\n\n    .category-checkbox-container > label {\n        cursor: inherit;\n    }\n\n    .category-checkbox-container:hover {\n        color: white;\n        cursor: pointer;\n    }\n\n    .category-checkbox-icon {\n        height: 30px;\n        width: 30px;\n        margin-bottom: 4px;\n    }\n\n    .category-checkbox-label {\n        text-align: center;\n        font-size: .75rem;\n        line-height: 1rem;\n    }\n\n    .category-checkbox-icon.active {\n        color: #66c530;\n    }\n\n    .category-checkbox-label.active {\n        color: white;\n    }\n\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
