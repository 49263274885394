import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
//import style from './custom-bootstrap.css';

class E3DCMapShadowRootComponent extends HTMLElement {
  connectedCallback() {

    const shadowRoot = this.attachShadow({ mode: 'open' });

    setTimeout(() => {
      document.querySelectorAll('script[data-e3dc-map-shadow-style="true"]').forEach((script) => {
        const styleElement = document.createElement("style");
        styleElement.textContent = script.textContent;
        shadowRoot.appendChild(styleElement);
        script.remove();
      });

      const container = document.createElement('div');
      shadowRoot.appendChild(container);

      ReactDOM.createRoot(container).render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    }, 0);
  }
}

// Define your custom element
customElements.define('e3dc-map-shadow-root-component', E3DCMapShadowRootComponent);

document.addEventListener('DOMContentLoaded', () => {
  const customElement = document.createElement('e3dc-map-shadow-root-component');
  const map = document.getElementById('e3dc-partner-map');
  if (map) {
    map.appendChild(customElement);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
