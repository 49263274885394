// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
    .search-result-container {
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: white;
    }

    .search-result-items-container {
        min-height: 334px;
    }

    .search-result-notice {
        font-size: .75rem;
        line-height: 1rem;
        color: darkgray;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/SearchResult.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;QACjB,kBAAkB;QAClB,kBAAkB;QAClB,uBAAuB;IAC3B;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;QACjB,iBAAiB;QACjB,eAAe;IACnB;AACJ","sourcesContent":[".map-container {\n    .search-result-container {\n        max-height: 400px;\n        overflow-y: scroll;\n        overflow-x: hidden;\n        background-color: white;\n    }\n\n    .search-result-items-container {\n        min-height: 334px;\n    }\n\n    .search-result-notice {\n        font-size: .75rem;\n        line-height: 1rem;\n        color: darkgray;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
