import { useState, useEffect, useRef } from 'react';
import './CategoryMultiSelect.css';
import CategoryCheckbox from "./CategoryCheckbox";

const CategoryMultiSelect = ({ categories, onChange }) => {

    const scrollContainerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [selectedCategories, setSelectedCategories] = useState({});

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
            top: 0,
            left: -100,
            behavior: 'smooth',
            });
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
            top: 0,
            left: 100,
            behavior: 'smooth',
            });
        }
    };

    const handleCheckboxChange = (categoryValue, isChecked) => {
        setSelectedCategories(prevState => {
            return { ...prevState, [categoryValue]: isChecked, }
        });
    };

    const watchScroll = (e) => {
        if (scrollContainerRef.current) {
            setShowLeftArrow(scrollContainerRef.current.scrollLeft !== 0);
            setShowRightArrow(scrollContainerRef.current.scrollLeft + scrollContainerRef.current.clientWidth !== scrollContainerRef.current.scrollWidth);
        }
    }

    useEffect(() => {
        var selected = {};
        categories.forEach(element => {
            selected[element.value] = element.active;
        });
        setSelectedCategories(selected);
    }, [categories]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedCategories);
        }
    }, [selectedCategories]);

    useEffect(() => {
        watchScroll();
        window.addEventListener('resize', watchScroll);
        return () => window.removeEventListener('resize', watchScroll);
    }, []);

    return (
        <div className='category-multi-select-container'>
            <div className='category-multi-select-overlay'>
                { showLeftArrow &&
                <span className='category-multi-select-overlay-arrow-left d-flex justify-content-start'>
                    <span className='d-flex align-items-center' onClick={handleScrollLeft}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                        </svg>
                    </span>
                </span>
                }
                { showRightArrow &&
                <span className='category-multi-select-overlay-arrow-right d-flex justify-content-end'>
                    <span className='d-flex align-items-center' onClick={handleScrollRight}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </span>
                </span>
                }
            </div>
            <div className='category-multi-select-content px-3 pb-2 d-flex justify-content-between align-items-center' ref={scrollContainerRef} onScroll={watchScroll}>
            {categories.map((category, index) => (
                <CategoryCheckbox
                    key={index}
                    category={category}
                    onChange={handleCheckboxChange}
                />
            ))}
            </div>
        </div>
    );
};

export default CategoryMultiSelect;